import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function enableBodyScrollLock(element) {
	disableBodyScroll(element);
}

function disableBodyScrollLock(element) {
	enableBodyScroll(element);
}

export {
	enableBodyScrollLock,
	disableBodyScrollLock
}